<template>
  <Card title="social_shipping.address_title">
    <ValidationForm
      tag="div"
      class="mt-4"
      :form="socialShippingFormBus.formFields"
      sub="addressForm"
      :v="socialShippingFormBus.$v"
      @submit="onSubmit" />
  </Card>
</template>

<script>
import Card from '@/components/Card';
import { STORE_MODULE_SOCIAL_SHIPPING } from '@/store/modules/social-shipping/constants';
import ValidationForm from '@/components/forms/ValidationForm';
import { debounce } from 'lodash-es/function';
import { hasAddressFinder } from '@/data/forms/hasAddressFinder';
import { socialShippingFormBus } from '@/views/SocialShipping/socialShippingFormBus';
import { ADDRESS_FINDER_FIELDS, ADDRESS_FINDER_GROUP, ADDRESS_FINDER_REQUIRED_FIELDS } from '@/data/forms/addressFinder';

export default {
  name: 'SocialShippingAddressForm',
  components: {
    Card,
    ValidationForm,
  },
  data() {
    /**
     * A short delay because looking up the address updates the recipient, triggering another update here. Nothing
     *  breaks without this delay, it just avoids one extra call to findAddress.
     */
    const DEBOUNCE_DELAY = 50;

    return {
      socialShippingFormBus,
      updateRecipientCallback: debounce(this.lookupAddress, DEBOUNCE_DELAY),
    };
  },

  mounted() {
    socialShippingFormBus.$on('update:recipient', this.updateRecipientCallback);
  },

  beforeDestroy() {
    socialShippingFormBus.$off('update:recipient', this.updateRecipientCallback);
  },

  methods: {
    onSubmit() {
      socialShippingFormBus.$emit('submit');
    },

    /**
     * @param {Object} newRecipient
     * @param {Object} oldRecipient
     * @returns {Promise<void>}
     */
    async lookupAddress(newRecipient, oldRecipient) {
      const newAddress = newRecipient[ADDRESS_FINDER_GROUP];
      if (!hasAddressFinder(newRecipient.cc) || !newAddress) {
        return;
      }

      const requiredFieldsArePresent = ADDRESS_FINDER_REQUIRED_FIELDS.every((field) => Boolean(newAddress[field]));
      if (!requiredFieldsArePresent) {
        return;
      }

      const anyFieldChanged = ADDRESS_FINDER_FIELDS.some((field) => newAddress[field] !== oldRecipient[ADDRESS_FINDER_GROUP][field]);
      if (!anyFieldChanged) {
        return;
      }

      socialShippingFormBus.$emit('findAddress:start');
      const response = await this.$store.dispatch(`${STORE_MODULE_SOCIAL_SHIPPING}/findAddress`, newAddress);
      socialShippingFormBus.$emit('findAddress:end', response);
    },
  },
};
</script>
